import React, { useState } from 'react';
import Slider from '../../Slider';
import { DEFAULT_NO_MAX_HORSEPOWER, SLIDER_MAX_VALUE } from '../../../constants/Engines';
import './styles.css';

const handleSliderMaxValue = (val) => {
  if (val === DEFAULT_NO_MAX_HORSEPOWER) {
    return SLIDER_MAX_VALUE;
  }
  return val;
};

const handleSliderMinValue = (val) => {
  if (val === '0') {
    return undefined;
  }
  return val;
};

const FilterHorsepower = ({ min, max, onChange, initialMax, handle }) => {
  // when changing slider with keyboard arrows, we don't want to trigger onChange multiple times.
  const [dragging, setDragging] = useState(false);

  const handleInputChange = (event) => {
    if (event.target.name === 'min') {
      onChange({ powerHp: { min: event.target.value, max } });
    } else {
      onChange({ powerHp: { min, max: event.target.value } });
    }
  };

  const handleSliderDrag = (handle, val) => {
    setDragging(true);
    if (handle === 'min') {
      onChange({ powerHp: { min: handleSliderMinValue(val.toString()), max } });
    } else {
      onChange({ powerHp: { min, max: handleSliderMaxValue(val.toString()) } });
    }
  };

  const handleSliderDragEnd = (handle, val) => {
    if (!dragging) {
      return;
    }
    setDragging(false);
    if (handle === 'min') {
      // if (tracking) {
      //   // TODO: Tracking as we do with price in BT:
      //   // this.debounceTracking({ ['price']: { min: val } });
      // }
      onChange({ powerHp: { min: handleSliderMinValue(val.toString()), max } });
    } else {
      // if (tracking) {
      //   // TODO: Tracking as we do with price in BT:
      //   // if (!val || val === DEFAULT_NO_MAX_HORSEPOWER) {
      //   //   this.debounceTrackingRemove('maximum price removed');
      //   // } else {
      //   //   this.debounceTracking({ ['price']: { max: val } });
      //   // }
      // }
      onChange({ powerHp: { min, max: handleSliderMaxValue(val.toString()) } });
    }
  };

  return (
    <div className="search-filter powerHp">
      <div className="slider-container">
        <Slider
          min={0}
          max={parseInt(DEFAULT_NO_MAX_HORSEPOWER)}
          initialMin={0}
          initialMax={initialMax || DEFAULT_NO_MAX_HORSEPOWER.toString()}
          value={[
            parseInt(min) || 0,
            parseInt(max) || parseInt(DEFAULT_NO_MAX_HORSEPOWER),
          ]}
          marks={{ 0: ' ' }}
          step={10}
          onChange={handleSliderDrag}
          onAfterChange={handleSliderDragEnd}
          handle={handle || ''}
        />
      </div>
      <div className="search-filter-group">
        <input
          type="number"
          className="powerHp-min"
          placeholder={'No Min'}
          name="min"
          value={min || ''}
          min={0}
          onChange={handleInputChange}
          title="Minimum powerHp"
        />
        <span>to</span>
        <input
          type="number"
          className="powerHp-max"
          name="max"
          placeholder={'No Max'}
          value={max || SLIDER_MAX_VALUE}
          min={0}
          onChange={handleInputChange}
          title="Maximum powerHp"
        />
      </div>
    </div>
  );
};

export default FilterHorsepower;
